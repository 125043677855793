import { DocsId } from '@grafana/data';

// TODO: Documentation links
const DOCS_LINKS: Record<DocsId, string> = {
  [DocsId.Transformations]:
    'http://docs.aws.amazon.com/grafana/latest/userguide/what-is-Amazon-Managed-Service-Grafana.html',
  [DocsId.FieldConfig]: 'https://grafana.com/docs/grafana/latest/panels/field-configuration-options/',
  [DocsId.FieldConfigOverrides]:
    'http://docs.aws.amazon.com/grafana/latest/userguide/what-is-Amazon-Managed-Service-Grafana.html',
};

export const getDocsLink = (id: DocsId) => DOCS_LINKS[id];
