import React from 'react';

import { LinkTarget } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Icon, IconName } from '@grafana/ui';
import { t } from 'app/core/internationalization';

import { init } from '../../shortbread/shortbread';

export interface FooterLink {
  target: LinkTarget;
  text: string;
  id: string;
  icon?: IconName;
  url?: string;
}

export let getFooterLinks = (): FooterLink[] => {
  return [
    {
      target: '_blank',
      id: 'documentation',
      text: t('nav.help/documentation', 'Documentation'),
      icon: 'document-info',
      url: 'https://docs.aws.amazon.com/grafana/latest/userguide/service-accounts.html',
    },
    {
      target: '_blank',
      id: 'support',
      text: t('nav.help/support', 'Support'),
      icon: 'question-circle',
      url: 'https://docs.aws.amazon.com/grafana/latest/userguide/service-accounts.html',
    },
    {
      target: '_blank',
      id: 'community',
      text: t('nav.help/community', 'Community'),
      icon: 'comments-alt',
      url: 'https://community.grafana.com/?utm_source=grafana_footer',
    },
  ];
};

export function getVersionMeta(version: string) {
  const isBeta = version.includes('-beta');

  return {
    hasReleaseNotes: true,
    isBeta,
  };
}

// AMG_CODEBASE: modify interface to support onClick
interface AmgFooterLink extends FooterLink {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export function getVersionLinks(hideEdition?: boolean): AmgFooterLink[] {
  const { buildInfo, licenseInfo } = config;
  const links: AmgFooterLink[] = [];
  const stateInfo = licenseInfo.stateInfo ? ` (${licenseInfo.stateInfo})` : '';
  const shortbread = init();

  if (!hideEdition) {
    links.push({
      target: '_blank',
      id: 'license',
      text: `${buildInfo.edition}${stateInfo}`,
      url: licenseInfo.licenseUrl,
    });
  }
  // AMG_CODEBASE: use different text for the footer
  if (config.featureToggles?.affirmativeCookieConsent) {
    links.push({
      target: '_self',
      id: 'cookies',
      text: t('nav.help/cookies', 'Cookies'),
      onClick: (e) => {
        e.preventDefault();
        shortbread?.customizeCookies();
      },
      url: '#',
    });
  }
  links.push({
    target: '_blank',
    id: 'version',
    text: `Amazon Grafana${stateInfo}`,
    url: 'http://docs.aws.amazon.com/grafana/latest/userguide/what-is-Amazon-Managed-Service-Grafana.html',
  });

  if (!buildInfo.hideVersion) {
    links.push({
      target: '_blank',
      id: 'version',
      text: `v${buildInfo.version}`,
    });
  }

  // AMG_CODEBASE: Commented out to remove 'New version available' link
  /*
  if (buildInfo.hasUpdate) {
    links.push({
      target: '_blank',
      id: 'updateVersion',
      text: `New version available!`,
      icon: 'download-alt',
      url: 'https://grafana.com/grafana/download?utm_source=grafana_footer',
    });
  }
  */

  return links;
}

export function setFooterLinksFn(fn: typeof getFooterLinks) {
  getFooterLinks = fn;
}

export interface Props {
  /** Link overrides to show specific links in the UI */
  customLinks?: FooterLink[] | null;
  hideEdition?: boolean;
}

export const Footer = React.memo(({ customLinks, hideEdition }: Props) => {
  const links = (customLinks || getFooterLinks()).concat(getVersionLinks(hideEdition));

  return (
    <footer className="footer">
      <div className="text-center">
        <ul>
          {links.map((link) => (
            <li key={link.text}>
              <FooterItem item={link} />
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';

function FooterItem({ item }: { item: AmgFooterLink }) {
  const content = item.url ? (
    <a href={item.url} target={item.target} rel="noopener noreferrer" id={item.id} onClick={item?.onClick}>
      {item.text}
    </a>
  ) : (
    item.text
  );

  return (
    <>
      {item.icon && <Icon name={item.icon} />} {content}
    </>
  );
}
